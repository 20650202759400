import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

import { SmallText } from "@webdex/UI/Typography";

const IntroText = styled(SmallText).attrs(props => {
  const {
    className
  } = props;

  return {
    as: "div",
    className: classNames(
      className,
      "chrome__header__intro-text-marquee__intro-text"
    )
  }
})`
  ${ ({ theme: { chrome } }) => `
    height: ${ chrome.mobile.headerIntroTextMarqueeHeight };
    line-height: ${ chrome.mobile.headerIntroTextMarqueeHeight };
    vertical-align: middle;
    margin-right: 0.3em;
  `};

  ${media.sm`
    ${ ({ theme: { chrome } }) => `
      && {
        height: ${ chrome.headerIntroTextMarqueeHeight };
        line-height: ${ chrome.headerIntroTextMarqueeHeight };
      }
    `}
  `}
`;

export default IntroText;
