import { globalHistory } from "@reach/router";

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!("IntersectionObserver" in window)) {
    require("intersection-observer");
    console.log("IntersectionObserver is polyfilled!");
  }
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  let { state = {} } = location;

  if (!state) {
    state = {}
  }

  return !state.disableScrollUpdate;
};

export const onInitialClientRender = () => {
  /**
   * This is a workaround for a bug in Gatsby
   *
   * See https://github.com/gatsbyjs/gatsby/issues/8357 for more details
   */
  globalHistory._onTransitionComplete();
};
