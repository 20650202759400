/*
 * Font Sizes
 *
 */
const measures = [
  "300px",  // xSmall
  "400px",  // small
  "600px",  // medium
  "800px",  // large
  "1200px", // xLarge
  "1600px"  // xxLarge
];

// Aliases
measures.xSmall = measures[0];
measures.small = measures[1];
measures.medium = measures[2];
measures.large = measures[3];
measures.xLarge = measures[4];
measures.xxLarge = measures[5];

export default measures;
