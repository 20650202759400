import React from "react";
import PropTypes from "prop-types";

import FooterComponent from "./components/Footer";
import FooterItem from "./views/FooterItem";
import FooterLink from "./views/FooterLink";

const Footer = props => {
  return (
    <FooterComponent>
      <FooterItem align="end">
        <FooterLink to="/imprint">
          Imprint
        </FooterLink>
      </FooterItem>
    </FooterComponent>
  );
};

export default Footer;
