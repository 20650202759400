import React, { useState } from "react";

import { isBrowser } from "@webdex/Utils/Window";

import IdleTimer from "react-idle-timer";

//Hooks
import { useWindowSize } from "@webdex/Utils/Window";
import { useDiscordMemesData } from "@webdex/Graph/hooks";

//Components
import ImageCluster from "../ImageCluster";

//Styles
import StyledMemeContainer from "./views/StyledMemeContainer";

//Main Component
const MemeOverlay = ({ imgWidth, intervalDuration, idleDuration, invert }) => {
  //Get Data and set Variables from CMS
  // const discordReacts = useDiscordReactsData();
  const discordMemes = useDiscordMemesData();

  const { width: windowWidth, height: windowHeight } = useWindowSize();

  const [isIdle, setIsIdle] = useState(false);

  const idleTrue = () => {
    setIsIdle(true);
  };

  const idleFalse = () => {
    setIsIdle(false);
  };

  return (
    <StyledMemeContainer>
      {
        isBrowser() && (
          <IdleTimer
            element={document}
            onActive={idleFalse}
            onIdle={idleTrue}
            // onAction={onAction}
            debounce={500}
            timeout={idleDuration}
          />
        )
      }

      {/* //The moving image is currently always active */}
      {/* {currentImage} */}

      {isIdle && (
        <ImageCluster
          windowHeight={windowHeight}
          windowWidth={windowWidth}
          imageData={discordMemes}
          imgWidth={imgWidth}
          invert={invert}
        ></ImageCluster>
      )}
    </StyledMemeContainer>
  );
};

export default MemeOverlay;
