import React from "react";
import PropTypes from "prop-types";
// import { globalHistory } from "@reach/router";
import resetCSS from "styled-reset";
import {
  BaseCSS as GridGlobalStyles,
  GridThemeProvider
} from "styled-bootstrap-grid";
import {
  createGlobalStyle,
  ThemeProvider as StyledThemeProvider
} from "styled-components";

const GlobalStyles = createGlobalStyle`
  ${resetCSS}

  ${ ({ theme: { colors, fonts, fontSizes, lineHeights } }) => `
    * {
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    html {
      background-color: ${ colors.black };
    }

    body {
      background-color: ${ colors.black };
      color: ${ colors.white };
      font-family: ${ fonts.sansSerif };
      font-size: ${ fontSizes.medium };
      line-height: ${ lineHeights.medium };
      overflow-x: hidden;
    }

    /*
     * Note:
     * It's necessary that we override strong and italic stylings due to
     * resetCSS forcing those tags to use "inherit" for their font property.
     *
     */
    strong, b, strong *, b * {
      font-weight: bold;
    }

    em, i, em *, i * {
      font-style: italic;
    }
  `}
`;

const ThemeProvider = props => {
  const { children, theme } = props;

  const {
    grid: gridTheme,
    ...styledTheme
  } = theme;

  return (
    <React.Fragment>
      <StyledThemeProvider theme={styledTheme}>
        <React.Fragment>
          <GlobalStyles/>
          <GridGlobalStyles/>
          <GridThemeProvider gridTheme={gridTheme}>
            { children }
          </GridThemeProvider>
        </React.Fragment>
      </StyledThemeProvider>
    </React.Fragment>
  );
};

ThemeProvider.propTypes = {
  theme: PropTypes.object.isRequired
}

export default ThemeProvider;
