import classNames from "classnames";
import PropTypes from "prop-types";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const ALIGNMENTS = {
  START: "start",
  CENTER: "center",
  END: "end"
};

const FooterItem = styled.div.attrs(props => {
  const {
    className,
    align,
    hiddenSmDown,
    hiddenSmUp
  } = props;

  return {
    className: classNames(className, "chrome__footer__footer-item", {
      "chrome__footer__footer-item--align-start": align === ALIGNMENTS.START,
      "chrome__footer__footer-item--align-center": align === ALIGNMENTS.CENTER,
      "chrome__footer__footer-item--align-end": align === ALIGNMENTS.END,

      "chrome__footer__footer-item--hidden-sm-down": hiddenSmDown,
      "chrome__footer__footer-item--hidden-sm-up": hiddenSmUp,
    })
  }
})`
  ${ ({ theme: { lineHeights } }) => `
    align-items: center;
    display: flex;
    flex: 1 0 0;
    height: ${ lineHeights.xSmall };

    &.chrome__footer__footer-item--align-start {
      justify-content: flex-start;
    }

    &.chrome__footer__footer-item--align-center {
      justify-content: center;
    }

    &.chrome__footer__footer-item--align-end {
      justify-content: flex-end;
    }

    &.chrome__footer__footer-item--hidden-sm-down {
      display: none;
    }

    &.chrome__footer__footer-item--hidden-sm-up {
      display: flex;
    }
  `};

  ${media.sm`
    ${ () => `
      && {
        &.chrome__footer__footer-item--hidden-sm-down {
          display: flex;
        }

        &.chrome__footer__footer-item--hidden-sm-up {
          display: none;
        }
      }
    `}
  `}
`;

FooterItem.propTypes = {
  align: PropTypes.oneOf(
    [
      ALIGNMENTS.START,
      ALIGNMENTS.CENTER,
      ALIGNMENTS.END
    ]
  ),
  hiddenSmDown: PropTypes.bool,
  hiddenSmUp: PropTypes.bool
};

FooterItem.defaultProps = {
  align: ALIGNMENTS.START,
  hiddenSmDown: false,
  hiddenSmUp: false
};

export default FooterItem;
