const REFERENCE_KEYS = {
  SUB_LAYOUT: "sublayout",
  ROUTE_TRANSITION: "routeTransition"
};

const SUB_LAYOUTS = {
  GLOSSARY_SUB_LAYOUT: "glossarySubLayout"
};

const ROUTE_TRANSITIONS = {
  ENTER_LEFT_EXIT_LEFT: "enterLeftExitLeft",
  ENTER_RIGHT_EXIT_RIGHT: "enterRightExitRight"
};

module.exports = {
  REFERENCE_KEYS: REFERENCE_KEYS,
  SUB_LAYOUTS: SUB_LAYOUTS,
  ROUTE_TRANSITIONS: ROUTE_TRANSITIONS
};
