import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";

const TRANSITION_NAME =
  `chrome__sub-layouts__transitions__enter-left-exit-left`;

const StyledEnterLeftExitLeft = styled.div.attrs(
  props => {
    const {
      className
    } = props;

    return {
      className: classNames(
        className,
        "chrome__sub-layouts__transitions__enter-left-exit-left"
      )
    };
  }
)`
  ${ ({ artificialDelayMs, transitionMs }) => `
    // TODO: Find a way around this absolute positioning shit.
    // position: absolute;
    // top: 0;
    // left: 0;
    // right: 0;

    //align-self: flex-start;
    width: 100vw;
    display: flex;
    flex: 0 0 100%;
    flex-direction: column;
    // min-height: calc(100 * var(--vh));
    min-width: 0;
    order: 1;

    // // transform: translate3d(0, 0, 0);
    // //will-change: transform;
    // -webkit-backface-visibility: hidden;
    // // -webkit-transform: translate3d(0,0,0);
    // // -webkit-transform-style: preserve-3d;

    // &.${ TRANSITION_NAME }-appear-active,
    // &.${ TRANSITION_NAME }-enter-active,
    // &.${ TRANSITION_NAME }-exit-active {
    //   transition:
    //     transform ${ transitionMs }ms cubic-bezier(.455, .03, .515, 1.0);
    // };

    // &.${ TRANSITION_NAME }-appear,
    // &.${ TRANSITION_NAME }-enter {
    //   transform: translateX(-100vw);
    // };

    // &.${ TRANSITION_NAME }-appear-active,
    // &.${ TRANSITION_NAME }-enter-active {
    //   transform: translateX(0);
    // };

    // &.${ TRANSITION_NAME }-appear-done,
    // &.${ TRANSITION_NAME }-enter-done {
    //   //transform: translateX(0);
    // };

    // &.${ TRANSITION_NAME }-exit {
    //   transform: translateX(0);
    //   height:100vh;
    // };

    // // Careful with these && declarations. These will override mobile settings
    // // and other settings that follow here.
    // && {
    //   &.${ TRANSITION_NAME }-exit-active {
    //     transform: translateX(-100vw);
    //     height:100vh;
    //   };
    // }
  `}
`;

StyledEnterLeftExitLeft.propTypes = {};

StyledEnterLeftExitLeft.defaultProps = {};

const EnterLeftExitLeft = props => {
  const {
    children,
    artificialDelayMs,
    transitionMs,
    ...passdownProps
  } = props;

  return (
    <CSSTransition
      classNames={{
        appear: `${ TRANSITION_NAME }-appear`,
        appearActive:`${ TRANSITION_NAME }-appear-active`,
        // Keep this. Prevents `react-transition-group` from adding
        // `undefined` to class name list.
        appearDone: `${ TRANSITION_NAME }-appear-done`,
        enter: `${ TRANSITION_NAME }-enter`,
        enterActive: `${ TRANSITION_NAME }-enter-active`,
        enterDone: `${ TRANSITION_NAME }-enter-done`,
        exit: `${ TRANSITION_NAME }-exit`,
        exitActive: `${ TRANSITION_NAME }-exit-active`,
        exitDone: `${ TRANSITION_NAME }-exit-done`,
      }}
      timeout={{
        appear: transitionMs + artificialDelayMs,
        enter: transitionMs + artificialDelayMs,
        exit: transitionMs + artificialDelayMs
      }}
      unmountOnExit
      { ...passdownProps }
    >
      <StyledEnterLeftExitLeft
        artificialDelayMs={ artificialDelayMs }
        transitionMs={ transitionMs }
      >
        { children }
      </StyledEnterLeftExitLeft>
    </CSSTransition>
  );
};

EnterLeftExitLeft.propTypes = {
  artificialDelayMs: PropTypes.number.isRequired,
  transitionMs: PropTypes.number.isRequired
};

export default EnterLeftExitLeft;
