import classNames from "classnames";
import styled from "styled-components";

const Y2K20MarqueeInnerWrapper = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "chrome__header__y2k20-marquee-inner-wrapper"
    )
  }
})`
  ${ () => `
    align-items: center;
    display: flex;
    justify-content: center;
    height: 50px;
  `};
`;

export default Y2K20MarqueeInnerWrapper;
