import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";

export const WEIGHTS = {
  NORMAL: "normal",
  BOLD: "bold"
};

const Text = styled.div.attrs(
  props => {
    const {
      noHighlight,
      truncate,
      uppercase,
      weight
    } = props;

    return {
      className: classNames("text", {
          "text--no-highlight": noHighlight,
          "text--truncated": truncate,
          "text--uppercase": uppercase,

          "text--weight-bold": weight === WEIGHTS.BOLD,
        })
    };
  }
)`
  ${({ theme: { colors, fontWeights } }) => `
    font: inherit;
    font-family: inherit;
    font-weight: ${ fontWeights.normal };
    letter-spacing: normal;

    &.text--no-highlight {
      user-select: none;
    }

    &.text--truncated {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.text--uppercase {
      text-transform: uppercase;
    }

    &.text--weight-bold {
      font-weight: ${ fontWeights.bold };
    }
  `}
`;

Text.propTypes = {
  noHighlight: PropTypes.bool,
  truncate: PropTypes.bool,
  uppercase: PropTypes.bool,
  weight: PropTypes.oneOf(
    [
      WEIGHTS.NORMAL,
      WEIGHTS.BOLD
    ]
  )
};

Text.defaultProps = {
  noHighlight: false,
  truncate: false,
  uppercase: false,
  weight: WEIGHTS.NORMAL
};

export default Text;
