import React, { useEffect, useRef } from "react";

import StyledMemeImage from "../MemeOverlay/views/StyledMemeImage";

import gsap, { Power3 } from "gsap";

import Backdrop from "./components/Backdrop";

const ImageCluster = ({
  windowHeight,
  windowWidth,
  imageData,
  imgWidth,
  invert,
}) => {
  const tl = new gsap.timeline();
  let images = useRef(null);

  const imgPossRef = useRef([]);

  const isOverlap = (x, y) => {
    let img;
    // return true if overlapping
    if (windowWidth > 800) {
      img = { x: imgWidth * 0.3, y: imgWidth * 0.4 };
    } else {
      img = { x: (imgWidth / 1.8) * 0.6, y: (imgWidth / 1.8) * 0.7 };
    }

    for (const imgPos of imgPossRef.current) {
      if (
        x > imgPos.randomleft - img.x &&
        x < imgPos.randomleft + img.x &&
        y > imgPos.randomtop - img.y &&
        y < imgPos.randomtop + img.y
      )
        return true;
    }
    return false;
  };

  // console.log("initializing cluster");

  //Call two hooks but this should be passed from higher up
  //   const discordReacts = useDiscordReactsData();
  // const { width: windowWidth, height: windowHeight } = useWindowSize();
  useEffect(() => {
    imgPossRef.current = [];
    const allImages = [...images.children];

    tl.to(
      allImages,
      5,
      {
        stagger: 0.1,
        autoAlpha: 1,
        yoyo: false,
        ease: Power3.easeOut,
      },
      0
    ).to(allImages, 1, { scale: 1.1, stagger: 0.1, repeat: -1, yoyo: true });
  }, [tl]);

  function getRandomPos(windowHeight, imgWidth) {
    const randomtop = Math.floor(Math.random() * (windowHeight - imgWidth));
    const randomleft = Math.floor(Math.random() * windowWidth);

    if (isOverlap(randomleft, randomtop)) {
      return getRandomPos(windowHeight, imgWidth);
    } else {
      imgPossRef.current.push({ randomleft, randomtop }); // record all img positions
      const newPos = { xPos: randomleft, yPos: randomtop };
      console.log(newPos);
      return newPos;
    }
  }

  return (
    <>
      <div ref={(el) => (images = el)}>
        {imageData.map((discordMeme, i) => {
          const pos = getRandomPos(windowHeight, imgWidth);
          // xPos = randomleft;
          // yPos = randomtop;
          return (
            <StyledMemeImage
              // style={{ width: "200px", height: "200px" }}
              // src={key.image.asset.url}
              src={discordMeme.image.asset.url}
              xPos={pos.xPos}
              yPos={pos.yPos}
              width={imgWidth}
              opacity={0}
              alt=""
              invert={invert}
              key={discordMeme._id}
            />
          );
        })}
      </div>
      <Backdrop invert={!invert} />
    </>
  );
};

export default React.memo(ImageCluster);
