import classNames from "classnames";
import styled, { css } from "styled-components";
import { media } from "styled-bootstrap-grid";
//import styled, { css, keyframes } from "styled-components";

// const KeyframesMeme = keyframes`
//   0% {
//     opacity: 0;
//   }
//   10% {
//     opacity: 0.5;
//   }
//   90% {
//     opacity: 0.5;
//   }
//   100% {
//     opacity: 0;
//   }
// `;

const StyledMemeImage = styled.img.attrs((props) => {
  const { className, invert } = props;

  return {
    className: classNames(className, "chrome__home__meme-display", {
      "chrome__home__meme-display--invert": invert,
    }),
  };
})`
  ${({ theme: { chrome, colors, space }, xPos, yPos, opacity, width }) => css`
    filter: invert(1);
    width: ${width + "px"};
    height: ${width + "px"};
    opacity: ${opacity};
    // transform: "translate(-50%, -50%)"
    position: absolute;
    top: ${yPos + "px"};
    left: ${xPos + "px"};
    z-index: 999;

    &.chrome__home__meme-display--invert {
      filter: invert(0);
    }
  `};

  ${media.sm`
    ${({ theme: { chrome }, xPos, yPos, opacity, width }) => `
      && {
        width: ${width / 1.8 + "px"};
    height: ${width / 1.8 + "px"};
      }
    `}
  `}

  ${media.lg`
    ${({ theme: { chrome }, xPos, yPos, opacity, width }) => `
      && {
        width: ${width + "px"};
    height: ${width + "px"};
      }
    `}
  `}
`;

export default StyledMemeImage;
