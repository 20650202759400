import React, { forwardRef, useMemo } from "react";
import { Marqy } from "marqy";
import PropTypes from "prop-types";

import StyledMarquee from "../views/Marquee";

const DIRECTION = {
  LEFT: "left",
  RIGHT: "right"
};

const Marquee = forwardRef((props, ref) => {
  const { children, direction, pauseOnHover, speed } = props;

  return useMemo(() => {
    return (
      <StyledMarquee ref={ref}>
        <Marqy
          speed={speed}
          direction={direction}
          pauseOnHover={pauseOnHover}
        >
          <div>
            { children }
          </div>
        </Marqy>
      </StyledMarquee>
    )
  }, [children, direction, pauseOnHover, ref, speed]);
});

Marquee.propTypes = {
  direction: PropTypes.oneOf([
    DIRECTION.LEFT,
    DIRECTION.RIGHT
  ]).isRequired,
  pauseOnHover: PropTypes.bool.isRequired,
  speed: (props, propName, componentName) => {
    if(typeof props[propName] != "number"){
      return new Error (`Invalid type of \`${ propName }\` supplied to \`${ componentName }\`. Validation failed.`);
    } else if (props[propName] < 0.0 || props[propName] > 1.0) {
      return new Error(`Invalid prop \`${ propName }\` supplied to \`${ componentName }\`. Validation failed.`);
    }
  }
};

Marquee.defaultProps = {
  direction: DIRECTION.LEFT,
  pauseOnHover: false,
  speed: 1.0
};

export default Marquee;
