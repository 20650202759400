import classNames from "classnames";

import styled from "styled-components";

const Chrome = styled.div.attrs((props) => ({
  className: classNames("chrome"),
}))``;

Chrome.defaultProps = {};

export default Chrome;
