/*
 * Borders
 *
 * All sizes in REM.
 *
 */
const borders = {};

borders.full = "inset 0 0 0 1px";
borders.bottom = "inset 0 -1px 0 0";
borders.left = "inset 1px 0 0 0";
borders.right = "inset -1px 0 0 0";
borders.top = "inset 0 1px 0 0";

export default borders;
