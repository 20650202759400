import classNames from "classnames";
import PropTypes from "prop-types";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const Header = styled.header.attrs(props => {
  const {
    className,
    invert
  } = props;

  return {
    className: classNames(
      className,
      "chrome__header", {
        "chrome__header__invert": invert
      }
    )
  }
})`
  ${ ({ theme: { chrome, colors, zPositions } }) => `
    cursor: pointer;
    align-items: flex-start;
    background: ${ colors.black };
    display: flex;
    flex-direction: column;
    height: 0;
    overflow: visible;
    //height: calc(${ chrome.mobile.headerHeight } + env(safe-area-inset-top, 0));
    isolation: isolate;
    padding: env(safe-area-inset-top, 0) 0;
    position: sticky;
    top: 0;
    transform: translateZ(0);
    width: 100vw;
    will-change: opacity;
    z-index: 1000;

    &.chrome__header__invert {
      background: ${ colors.white };
    }
  `};

  ${media.sm`
    ${ ({ theme: { chrome } }) => `
      && {
        //height: calc(${ chrome.headerHeight } + env(safe-area-inset-top, 0));
        padding: env(safe-area-inset-top, 0) 0;
      }
    `}
  `}
`;

Header.propTypes = {
  invert: PropTypes.bool
};

Header.defaultProps = {
  invert: false
};

export default Header;
