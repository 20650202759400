import React from "react";

import Footer from "@webdex/Layouts/App/components/Footer";
import Header from "@webdex/Layouts/App/components/Header";
import { MemeOverlay } from "@webdex/Layouts/App/components/MemeOverlay";

const EmptySubLayout = (props) => {
  const { children } = props;

  return (
    <React.Fragment>
{/*      <MemeOverlay
        imgWidth={200}
        intervalDuration={5000}
        idleDuration={400000}
      />*/}
      <Header />
      {children}
      <Footer />
    </React.Fragment>
  );
};

export default EmptySubLayout;
