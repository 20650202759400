import classNames from "classnames";
import styled, { css, keyframes } from "styled-components";

const marqyL = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
`;

const marqyR = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
`;

const Marquee = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "webdex-ui__marquee")
  }
})`
  ${ () => css`
    width: 100%;

    [data-marqy] {
      position: relative;
      overflow: hidden;
    }

    [data-marqy-inner] {
      display: flex;
    }

    [data-marqy][data-direction='right'] [data-marqy-inner] {
      justify-content: flex-end;
    }

    [data-marqy-content] {
      display: flex;
      flex: 1 0 auto;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-play-state: running;
      will-change: transform;
    }

    [data-marqy][data-direction='left'] [data-marqy-content] {
      animation-name: ${ marqyL };
    }

    [data-marqy][data-direction='right'] [data-marqy-content] {
      animation-name: ${ marqyR };
    }

    [data-marqy][data-pause-on-hover]:hover [data-marqy-content] {
      animation-play-state: paused;
    }

    [data-marqy-item] {
      flex-grow: 0;
    }

    @media (prefers-reduced-motion) {
      [data-marqy-inner] {
        overflow-x: scroll;
      }

      [data-marqy-content] {
        animation: none !important;
      }
    }
  `};
`;

export default Marquee;
