import classNames from "classnames";
import styled from "styled-components";

const StyledMemeContainer = styled.div.attrs((props) => {
  const { className } = props;

  return {
    className: classNames(className, "chrome__home__meme-display"),
  };
})`
  ${({ theme: { chrome, colors, space } }) => `
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 999999;
    pointer-events: none;
  `};
`;

export default StyledMemeContainer;
