/*
 * zPositions
 *
 */
const zPositions = {
  base: 0,  // Base
  above: 1, // Use this for all values above the base.
  below: -1 // Use this for all values below the base.
};

export default zPositions;
