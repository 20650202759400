import React from "react";
import { navigate } from "gatsby";
import PropTypes from "prop-types";

import { Marquee } from "@webdex/UI/Marquee";

import NMCodexText from "./views/NMCodexText";
import Y2K20Logo from "./views/Y2K20Logo";
import StyledY2K20Marquee from "./views/Y2K20Marquee";
import Y2K20MarqueeInnerWrapper from "./views/Y2K20MarqueeInnerWrapper";

const Y2K20Marquee = props => {
  const { invert } = props;

  const goToHome = () => {
    navigate("/");
  };

  return (
    <StyledY2K20Marquee invert={invert} onClick={goToHome}>
      <Marquee>
        <Y2K20MarqueeInnerWrapper>
          <NMCodexText>
            NM WEBDEX
          </NMCodexText>
          <Y2K20Logo invert={invert}/>
        </Y2K20MarqueeInnerWrapper>
      </Marquee>
    </StyledY2K20Marquee>
  );
};

Y2K20Marquee.propTypes = {
  invert: PropTypes.bool
};

Y2K20Marquee.defaultProps = {
  invert: false
};

export default Y2K20Marquee;
