import { useStaticQuery, graphql } from "gatsby";

const GET_DISCORD_REACT_PREVIEW = graphql`
  query DiscordReactPreviewData {
    sanityDiscordReactPreview {
      ...fieldsOnDiscordReactPreview
    }
  }
`;

export const useDiscordReactPreviewData = () => {
  const {
    sanityDiscordReactPreview: discordReactPreview
  } = useStaticQuery(GET_DISCORD_REACT_PREVIEW);

  return discordReactPreview;
};

export default useDiscordReactPreviewData;
