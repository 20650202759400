/*
 * Font Sizes
 *
 */
const fontSizes = [
  "12px", // xSmall
  "16px", // small
  "24px", // medium
  "32px", // large
  "48px", // xLarge
  "64px"  // xxLarge
];

// Aliases
fontSizes.xSmall = fontSizes[0];
fontSizes.small = fontSizes[1];
fontSizes.medium = fontSizes[2];
fontSizes.large = fontSizes[3];
fontSizes.xLarge = fontSizes[4];
fontSizes.xxLarge = fontSizes[5];

export default fontSizes;
