import classNames from "classnames";
import styled from "styled-components";
import Color from "color";

const StyledMemeContainer = styled.div.attrs((props) => {
  const { className, invert } = props;

  return {
    className: classNames("chrome__home__meme-backdrop", className, {
      "chrome__home__meme-backdrop--invert": invert,
    }),
  };
})`
  ${({ theme: { colors, space } }) => `
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;
    background-color: ${Color(colors.white).alpha(0.7)};
    backdrop-filter: blur(9px);
    transition: opacity 20ms cubic-bezier(0.39, 0.575, 0.565, 1);
    will-change: opacity;

    ${"" /* firefox only targeting since no support for backdrop-filter */}
    @-moz-document url-prefix() {
      & {
        background-color: ${Color(colors.white).alpha(0.85)};
      }
    }

    &.chrome__home__meme-backdrop--invert {
      background-color: ${Color(colors.black).alpha(0.7)};

      @-moz-document url-prefix() {
        & {
          background-color: ${Color(colors.black).alpha(0.85)};
        }
      }
    }
    
  `};
`;

export default StyledMemeContainer;
