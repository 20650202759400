import React, { useMemo } from "react";
import PropTypes from "prop-types";

import IntroTextMarquee from "./components/IntroTextMarquee";
import Y2K20Marquee from "./components/Y2K20Marquee";
import StyledHeader from "./views/Header";

const Header = (props) => {
  const { invert } = props;

  return useMemo(
    () => (
      <StyledHeader role="link" invert={invert}>
        <Y2K20Marquee invert={invert} />
        <IntroTextMarquee invert={invert} />
      </StyledHeader>
    ),
    [invert]
  );
};

Header.propTypes = {
  invert: PropTypes.bool,
};

Header.defaultProps = {
  invert: false,
};

export default Header;
