/*
 * Chrome Specifications
 *
 */
const chrome = {};

chrome.headerY2K20MarqueeHeight = "40px";
chrome.headerIntroTextMarqueeHeight = "20px";
chrome.headerHeight = "60px";
chrome.footerHeight = "20px";

chrome.mobile = {};
chrome.mobile.headerY2K20MarqueeHeight = "40px";
chrome.mobile.headerIntroTextMarqueeHeight = "20px";
chrome.mobile.headerHeight = "60px";
chrome.mobile.footerHeight = "20px";

export default chrome;
