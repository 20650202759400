import React from "react";
import { navigate } from "gatsby";
import PropTypes from "prop-types";

import { Marquee } from "@webdex/UI/Marquee";

import IntroText from "./views/IntroText";
import StyledIntroTextMarquee from "./views/IntroTextMarquee";

const IntroTextMarquee = props => {
  const { invert } = props;

  const goToIntroductionText = () => {
    navigate("/introduction");
  };

  return (
    <StyledIntroTextMarquee invert={invert} onClick={goToIntroductionText}>
      <Marquee speed={0.5}>
        <IntroText>
          {
            // TODO: This text should come from CMS etc.
          }
          NM WebDex Y2K20 records the fugitive meanings of New Models’ vernacular as collectively defined by a swarm of NM dark forest dwellers in early 2021.
        </IntroText>
      </Marquee>
    </StyledIntroTextMarquee>
  );
};

IntroTextMarquee.propTypes = {
  invert: PropTypes.bool
};

IntroTextMarquee.defaultProps = {
  invert: false
};

export default IntroTextMarquee;
