import React, { useEffect, useState } from "react";

import { AppSystem, Metadata } from "@webdex/Core";
import { useHomePageData } from "@webdex/Graph/hooks";
import { Main as MainTheme, ThemeProvider } from "@webdex/UI/Themes";
import SubLayoutContext from "./subLayouts/contexts/SubLayoutContext";

import Chrome from "./components/Chrome";

import { constants, EmptySubLayout, GlossarySubLayout } from "./subLayouts";

const getSubLayoutComponent = (subLayoutFromContext) => {
  switch (subLayoutFromContext) {
    case constants.SUB_LAYOUTS.GLOSSARY_SUB_LAYOUT:
      return GlossarySubLayout;
    default:
      return EmptySubLayout;
  }
};

const App = (props) => {
  const { children, location, pageContext } = props;

  const { metadata } = useHomePageData();

  const subLayoutFromContext = pageContext[constants.REFERENCE_KEYS.SUB_LAYOUT];

  const [SubLayout, setSubLayout] = useState(() => {
    return getSubLayoutComponent(subLayoutFromContext);
  });

  const [isTransitioning, setIsTransitioning] = useState(false);
  const [
    lastScrollHTMLTopBeforeTransition,
    setLastHTMLScrollTopBeforeTransition,
  ] = useState(0.0);

  useEffect(() => {
    setSubLayout(() => getSubLayoutComponent(subLayoutFromContext));
  }, [subLayoutFromContext]);

  const system = {
    isTransitioning: isTransitioning,
    lastScrollHTMLTopBeforeTransition: lastScrollHTMLTopBeforeTransition,
    setIsTransitioning: setIsTransitioning,
    setLastHTMLScrollTopBeforeTransition: setLastHTMLScrollTopBeforeTransition,
  };

  return (
    <ThemeProvider theme={MainTheme}>
      <AppSystem>
        <Metadata metadata={metadata} />

        {/* <MemeOverlay
          imgWidth={200}
          intervalDuration={5000}
          idleDuration={10000}
        /> */}

        <SubLayoutContext.Provider value={system}>
          <Chrome>
            <SubLayout location={location} pageContext={pageContext}>
              {children}
            </SubLayout>
          </Chrome>
        </SubLayoutContext.Provider>
      </AppSystem>
    </ThemeProvider>
  );
};

export default App;
