import classNames from "classnames";
import PropTypes from "prop-types";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const Y2K20Marquee = styled.div.attrs(props => {
  const {
    className,
    invert
  } = props;

  return {
    className: classNames(
      className,
      "chrome__header__y2k20-marquee",
      {
        "chrome__header__y2k20-marquee__invert": invert
      }
    )
  }
})`
  ${ ({ theme: { borders, chrome, colors } }) => `
    align-items: center;
    background: ${ colors.black };
    box-shadow: ${ borders.bottom } ${ colors.white };
    color: ${ colors.white };
    display: flex;
    height: ${ chrome.mobile.headerY2K20MarqueeHeight };
    width: 100%;

    &.chrome__header__y2k20-marquee__invert {
      background: ${ colors.white };
      box-shadow: ${ borders.bottom } ${ colors.black };
      color: ${ colors.black };
    }
  `};

  ${media.sm`
    ${ ({ theme: { chrome } }) => `
      && {
        height: ${ chrome.headerY2K20MarqueeHeight };
      }
    `}
  `}
`;

Y2K20Marquee.propTypes = {
  invert: PropTypes.bool
};

Y2K20Marquee.defaultProps = {
  invert: false
};

export default Y2K20Marquee;
