import React, { useContext, useEffect, useRef, useState } from "react";
import { TransitionGroup } from "react-transition-group";

import isWindow from "dom-helpers/query/isWindow";
import ownerDocument from "dom-helpers/ownerDocument";
import ownerWindow from "dom-helpers/ownerWindow";
import css from "dom-helpers/style";
import getScrollbarSize from "dom-helpers/util/scrollbarSize";

import Footer from "@webdex/Layouts/App/components/Footer";
import { isBrowser } from "@webdex/Utils/Window";

import constants from "../../constants";
import SubLayoutContext from "../../contexts/SubLayoutContext";
import EnterLeftExitLeft from "../../transitions/EnterLeftExitLeft";
import EnterRightExitRight from "../../transitions/EnterRightExitRight";

import StyledGlossarySubLayout from "./views/GlossarySubLayout";

const getTransitionComponent = (transitionFromContext) => {
  switch (transitionFromContext) {
    case constants.ROUTE_TRANSITIONS.ENTER_LEFT_EXIT_LEFT:
      return EnterLeftExitLeft;
    case constants.ROUTE_TRANSITIONS.ENTER_RIGHT_EXIT_RIGHT:
      return EnterRightExitRight;
    default:
      return null;
  };
};

const GlossarySubLayout = props => {
  const { children, location, pageContext } = props;

  const subLayoutContext = useContext(SubLayoutContext);

  const subLayoutContextRef = useRef(subLayoutContext);

  const transitionFromContext = pageContext[
    constants.REFERENCE_KEYS.ROUTE_TRANSITION
  ];

  const [Transition, setTransition] = useState(() => {
    return getTransitionComponent(transitionFromContext);
  });

  const isFirstLoadRef = useRef(true);
  const previousBodyOverflowRef = useRef("");
  const previousBodyPaddingRightRef = useRef("");
  const previousBodyPositionRef = useRef("");
  const previousBodyLeftRef = useRef("");
  const previousBodyRightRef = useRef("");

  useEffect(() => {
    if (!isBrowser() || !transitionFromContext) { return; }

    let transitionTimeout;

    const bodyElement = document.querySelector("body");
    const htmlElement = document.querySelector("html");

    const subLayoutContext = subLayoutContextRef.current;

    const isOverflowing = element => {
      const doc = ownerDocument(element);
      const win = ownerWindow(doc);

      const isBody = element && element.tagName.toLowerCase() === "body";

      if (!isWindow(doc) && !isBody) {
        return element.scrollHeight > element.clientHeight;
      }

      const style = win.getComputedStyle(doc.body);
      const marginLeft = parseInt(style.getPropertyValue("margin-left"), 10);
      const marginRight = parseInt(style.getPropertyValue("margin-right"), 10);

      return marginLeft + doc.body.clientWidth + marginRight < win.innerWidth;
    };

    const setLastScrollTopBeforeTransition = () => {
      subLayoutContext.setLastHTMLScrollTopBeforeTransition(
        htmlElement.scrollTop
      );
    };

    const setTransitioningStyles = () => {
      let nextPaddingRight;

      if (isOverflowing(bodyElement)) {
        const scrollbarSize = getScrollbarSize();
        const bodyPaddingRight =
          parseInt(css(bodyElement, "paddingRight") || 0, 10);

        nextPaddingRight = bodyPaddingRight + scrollbarSize;
      }

      previousBodyOverflowRef.current = bodyElement.style.overflow;
      previousBodyLeftRef.current = bodyElement.style.left;
      previousBodyPaddingRightRef.current = bodyElement.style.paddingRight;
      previousBodyPositionRef.current = bodyElement.style.position;
      previousBodyRightRef.current = bodyElement.style.right;

      bodyElement.style.overflow = "hidden";
      bodyElement.style.left = "0";
      bodyElement.style.position = "fixed";
      bodyElement.style.right = "0";

      if (nextPaddingRight) {
        bodyElement.style.paddingRight = `${ nextPaddingRight }px`;
      }
    };

    const setTransitioningCompleteStyles = () => {
      bodyElement.style.overflow = previousBodyOverflowRef.current;
      bodyElement.style.left = previousBodyLeftRef.current;
      bodyElement.style.paddingRight = previousBodyPaddingRightRef.current;
      bodyElement.style.position = previousBodyPositionRef.current;
      bodyElement.style.right = previousBodyRightRef.current;
    };

    const startTransition = () => {
      setLastScrollTopBeforeTransition();
      setTransitioningStyles();
      setTransition(() => getTransitionComponent(transitionFromContext));
      subLayoutContext.setIsTransitioning(true);
    };

    const stopTransition = () => {
      setTransitioningCompleteStyles();
      subLayoutContext.setIsTransitioning(false);
    };

    if (isFirstLoadRef.current === true) {
      // TODO: We shouldnt have to do this. Why do we?
      /* --- */
          startTransition();

          transitionTimeout = setTimeout(
            stopTransition,
            GlossarySubLayout.ARTIFICIAL_DELAY_MS +
            GlossarySubLayout.TRANSITION_MS
          );
      /* --- */

      isFirstLoadRef.current = false;
    } else {
      startTransition();

      transitionTimeout = setTimeout(
        stopTransition,
        GlossarySubLayout.ARTIFICIAL_DELAY_MS +
        GlossarySubLayout.TRANSITION_MS
      );
    }

    return () => {
      clearTimeout(transitionTimeout);
      stopTransition();
    };
  }, [transitionFromContext]);

  return (
    <React.Fragment>
      <StyledGlossarySubLayout
        in={
          transitionFromContext ===
          constants.ROUTE_TRANSITIONS.ENTER_RIGHT_EXIT_RIGHT
        }
        artificialDelayMs={GlossarySubLayout.ARTIFICIAL_DELAY_MS}
        transitionMs={GlossarySubLayout.TRANSITION_MS}
      >
        {
          !!Transition ? (
            <TransitionGroup component={null}>
              <Transition
                key={location.pathname}
                artificialDelayMs={GlossarySubLayout.ARTIFICIAL_DELAY_MS}
                transitionMs={GlossarySubLayout.TRANSITION_MS}
              >
                { children }
              </Transition>
            </TransitionGroup>
          ) : (
            { children }
          )
        }
      </StyledGlossarySubLayout>
      <Footer/>
    </React.Fragment>
  );
}

GlossarySubLayout.TRANSITION_MS = 500;
GlossarySubLayout.ARTIFICIAL_DELAY_MS = 164;

export default GlossarySubLayout;
