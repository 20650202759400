import borders from "./attributes/borders";
import chrome from "./attributes/chrome";
import colors from "./attributes/colors";
import fonts from "./attributes/fonts";
import fontSizes from "./attributes/fontSizes";
import fontWeights from "./attributes/fontWeights";
import grid from "./attributes/grid";
import lineHeights from "./attributes/lineHeights";
import measures from "./attributes/measures";
import radii from "./attributes/radii";
import space from "./attributes/space";
import zPositions from "./attributes/zPositions";

const Main = {
  borders: borders,
  chrome: chrome,
  colors: colors,
  fonts: fonts,
  fontSizes: fontSizes,
  fontWeights: fontWeights,
  grid: grid,
  lineHeights: lineHeights,
  measures: measures,
  radii: radii,
  space: space,
  zPositions: zPositions
};

export default Main;
