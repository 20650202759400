import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const NMCodexText = styled.span.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "chrome__header__y2k20-marquee__nm-codex-text"
    )
  }
})`
  ${ ({ theme: { chrome, colors, space } }) => `
    font-size: 47.5px;
    height: ${ chrome.mobile.headerY2K20MarqueeHeight };
    line-height: ${ chrome.mobile.headerY2K20MarqueeHeight };
    margin-right: ${ space.small };
    vertical-align: middle;
  `};

  ${media.sm`
    ${ ({ theme: { chrome } }) => `
      && {
        height: ${ chrome.headerY2K20MarqueeHeight };
        line-height: ${ chrome.headerY2K20MarqueeHeight };
      }
    `}
  `}
`;

export default NMCodexText;
