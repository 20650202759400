/*
 * Line Heights
 *
 */
const lineHeights = [
  "15px", // xSmall
  "20px", // small
  "30px", // medium
  "40px", // large
  "60px", // xLarge
  "80px"  // xxLarge
];

// Aliases
lineHeights.xSmall = lineHeights[0];
lineHeights.small = lineHeights[1];
lineHeights.medium = lineHeights[2];
lineHeights.large = lineHeights[3];
lineHeights.xLarge = lineHeights[4];
lineHeights.xxLarge = lineHeights[5];

export default lineHeights;
