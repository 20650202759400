import React from "react";
import PropTypes from "prop-types";

import StyledFooter from "./views/Footer";

/*
 * Note on `bodyPaddingRight` hack.
 *
 * Within some of our components (e.g.; the About Panel component), we sometimes
 * update styles on the `body` tag in order to account for the style concerns of
 * a given component (such as setting the `body` to `overflow: hidden` whenever
 * the About Panel is open to prevent body scrolling).
 *
 * In these situations we sometimes need to account for the scrollbar on the
 * page and to adjust the `padding-right` on the page in order to account for
 * changes to the page width when removing the scrollbar so as not cause page
 * contents to pop around. However, because the Footer component is
 * `position: static`, we additionally need to account for these padding changes
 * on the Footer. We determined that here was the best place to listen and
 * account for these changes in inline styles on the `body`, as this isn't the
 * concern of those components themselves. If we ever choose to remove static
 * Footer styling, we can remove this code.
 *
 */

const Footer = props => {
  const { children } = props;

  // const [bodyPaddingRight, setBodyPaddingRight] = useState(0.0);

  // useEffect(() => {
  //   if (typeof window !== "undefined") { return; }

  //   const bodyElement = document.querySelector("body");

  //   const getBodyPaddingRight = () => {
  //     const bodyPaddingRight = bodyElement.style.paddingRight;

  //     return bodyPaddingRight;
  //   };

  //   const observer = new MutationObserver(mutations => {
  //     setBodyPaddingRight(getBodyPaddingRight());
  //   });

  //   observer.observe(bodyElement, {
  //     attributeFilter: ["style"],
  //     attributes: true
  //   });

  //   return () => {
  //     observer.disconnect();
  //   }
  // }, []);

  // return (
  //   <StyledFooter
  //     bodyPaddingRight={bodyPaddingRight}
  //     invert={invert}
  //   >
  //     { children }
  //   </StyledFooter>
  // );

  return (
    <StyledFooter>
      { children }
    </StyledFooter>
  );
};

export default Footer;
