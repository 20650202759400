import { useStaticQuery, graphql } from "gatsby";

const GET_DISCORD_REACTS = graphql`
  query DiscordReactData {
    allSanityDiscordReact {
      nodes {
        ...fieldsOnDiscordReact
      }
    }
  }
`;

export const useDiscordReactsData = () => {
  const {
    allSanityDiscordReact: {
      nodes: discordReacts
    }
  } = useStaticQuery(GET_DISCORD_REACTS)

  return discordReacts;
};

export default useDiscordReactsData;
