/*
 * Space
 *
 */
const space = [
  "5px",  // xSmall
  "10px", // small
  "20px", // medium
  "40px", // large
  "80px"  // xLarge
];

// Aliases
space.xSmall = space[0];
space.small = space[1];
space.medium = space[2];
space.large = space[3];
space.xLarge = space[4];

export default space;
