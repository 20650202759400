/*
 * Radii
 *
 */
const radii = [];

radii.circle = "100%";
radii.pill = "9999px";
radii.reactsToggle = "40px";

export default radii;
