/*
 * Colors
 *
 */
const black = "#000000";
const white = "#FFFFFF";
const yellow = "#FFFF00";
const transparent = "transparent";

const colors = {
  black: black,
  white: white,
  yellow: yellow,
  transparent: transparent,
};

export default colors;
