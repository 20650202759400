import React, { forwardRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";

import { Logo } from "@webdex/UI/Logo";

const Y2K20LogoWrapper = styled.span.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "chrome__header__y2k20-marquee__y2k20-logo"
    )
  }
})`
  ${ ({ theme: { chrome, colors, space } }) => `
    height: ${ chrome.headerY2K20MarqueeHeight };
    margin-right: ${ space.small };
    vertical-align: middle;

    svg {
      height: calc(${ chrome.headerY2K20MarqueeHeight } + 7px);
      margin-top: -2px;
      vertical-align: middle;
    }
  `};
`;

const Y2K20Logo = forwardRef((props, ref) => {
  const { invert, theme } = props;

  return (
    <Y2K20LogoWrapper ref={ref}>
      <Logo color={ invert ? theme.colors.black : theme.colors.white }/>
    </Y2K20LogoWrapper>
  );
});

Y2K20Logo.propTypes = {
  invert: PropTypes.bool
};

Y2K20Logo.defaultProps = {
  invert: false
};

export default withTheme(Y2K20Logo);
