import classNames from "classnames";
import PropTypes from "prop-types";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const Footer = styled.footer.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "chrome__footer"
    )
  }
})`
  ${
    (
      {
        bodyPaddingRight, theme: {
          borders, chrome, colors, lineHeights, space
        }
      }
    ) => `
      align-items: center;
      bottom: 0;
      color: ${ colors.white };
      display: flex;
      height: ${ chrome.mobile.footerHeight };
      left: ${ space.xSmall };
      mix-blend-mode: exclusion;
      padding: 0 ${ space.xLarge };
      position: fixed;
      right: ${ space.xSmall };
      text-transform: uppercase;
      transform: translateZ(0);
      will-change: opacity;
      z-index: 1000;

      ${ bodyPaddingRight
        ? `padding-right: calc(${ bodyPaddingRight } + ${ space.xLarge });`
        : ""
      }
    `};

  ${media.sm`
    ${ ({ bodyPaddingRight, theme: { chrome, space } }) => `
      && {
        height: ${ chrome.footerHeight };
        left: ${ space.xSmall };
        padding: 0 ${ space.xLarge };
        right: ${ space.xSmall };

        ${ bodyPaddingRight
          ? `padding-right: calc(${ bodyPaddingRight } + ${ space.xLarge });`
          : ""
        }
      }
    `}
  `}
`;

Footer.propTypes = {
};

Footer.defaultProps = {
  bodyPaddingRight: 0
};

export default Footer;
