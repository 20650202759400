import { useStaticQuery, graphql } from "gatsby";

const GET_DISCORD_MEMES = graphql`
  query DiscordMemesData {
    allSanityDiscordMeme {
      nodes {
        ...fieldsOnDiscordMeme
      }
    }
  }
`;

export const useDiscordMemesData = () => {
  const {
    allSanityDiscordMeme: {
      nodes: discordMemes
    }
  } = useStaticQuery(GET_DISCORD_MEMES)

  return discordMemes;
};

export default useDiscordMemesData;
