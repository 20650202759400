import React from "react";

import { AppContext } from "@webdex/Core";

const AppSystem = (props) => {
  const { children } = props;

  const system = {};

  return <AppContext.Provider value={system}>{children}</AppContext.Provider>;
};

export default AppSystem;
